var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "leftColumn" }, [
    _c(
      "div",
      [
        _c(
          "graphTitle",
          { attrs: { title: "车牌绑定情况" } },
          [
            _c(
              "el-tooltip",
              {
                attrs: {
                  placement: "top",
                  content:
                    "展示统计时间段内发生停车行为的车辆中，被会员绑定的车辆与未被会员绑定的车辆占比情况",
                },
              },
              [_c("i", { staticClass: "el-icon-question my-icon" })]
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { style: { height: "260px" }, attrs: { id: "pied" } }),
    _c(
      "p",
      {
        staticStyle: {
          "text-align": "center",
          "font-size": "17px",
          color: "rgba(0,0,0,0.65)",
        },
      },
      [_vm._v("已知车辆数量：" + _vm._s(_vm.pieData.allPlates || 0) + "辆")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }