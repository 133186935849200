var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "leftColumn" },
    [
      _c(
        "graphTitle",
        { attrs: { title: "绑定车牌用户占比" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content:
                  "展示统计时间段内注册的会员当中，已绑定车辆的会员数与未绑定车辆的会员数的占比情况",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
        ],
        1
      ),
      _c("div", { style: { height: "260px" }, attrs: { id: "piec" } }),
      _c(
        "p",
        {
          staticStyle: {
            "text-align": "center",
            color: "rgba(0,0,0,0.65)",
            "font-size": "17px",
          },
        },
        [_vm._v("注册用户总数：" + _vm._s(_vm.rightData.allUsers || 0) + "个")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }