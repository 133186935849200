var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "graphTitle",
        { attrs: { title: "新增用户趋势图" } },
        [
          _c(
            "el-tooltip",
            {
              attrs: {
                placement: "top",
                content:
                  "展示统计时间段内每日或每月产生的注册会员数量趋势，车牌绑定：统计当天新增绑定车牌的用户数量",
              },
            },
            [_c("i", { staticClass: "el-icon-question my-icon" })]
          ),
        ],
        1
      ),
      _c("div", {
        staticClass: "circularChart",
        attrs: { id: "circularChart" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }